import ImageWrapper from '@components/Image';
import { Typography } from '@components/Typography';
import clsx from 'clsx';
import Link from 'next/link';
import { CardProps } from './Card';

const CardBasic: React.FC<CardProps> = ({
  image,
  heading,
  link,
  className,
  priority,
}) => {
  return (
    <span className={clsx('relative flex w-full flex-col', className)}>
      <Link href={link} className="group w-full" title={heading}>
        <span className="relative block h-[220px] overflow-hidden rounded-xl bg-gray-100 md:h-[280px]">
          <ImageWrapper
            src={image.src}
            alt={heading}
            fullHeight
            cover
            fullWidth
            dimensions={{ width: 340, height: 200 }}
            imgClassName={'group-hover:scale-105 nice-transition'}
            className=" group-hover:shadow-lg "
            priority={priority}
          />
        </span>
      </Link>

      <Typography
        tag="p"
        size="text-xl md:text-base"
        weight="font-semibold"
        className="nice-transition">
        <Link href={link} className="block w-full py-3 hover:underline md:py-2">
          {heading}
        </Link>
      </Typography>
    </span>
  );
};
export default CardBasic;
