'use client';

import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';
import Caption from './Caption';

interface ImageLoaderProps {
  src: string;
  width?: number;
  quality?: number;
  [key: string]: any; // For other props that might be passed
}

type ImageDimension = {
  height: number;
  width: number;
};

export type AspectRatios =
  | 'aspect-auto'
  | 'aspect-video'
  | 'aspect-square'
  | 'aspect-imax'
  | 'aspect-crt'
  | 'aspect-superwide'
  | string;

export interface ImageProps {
  // Required
  src: string;

  // Optional
  alt?: string;

  dimensions?: ImageDimension;
  fullWidth?: boolean;
  fullHeight?: boolean;
  cover?: boolean;
  aspect?: AspectRatios;
  className?: string;
  imgClassName?: string;
  priority?: boolean;
  quality?: number;
  format?: 'webp' | 'png';
  unoptimized?: boolean;
  blur?: boolean;
  blurData?: string;
  caption?: boolean;
  itemProp?: boolean;
}

export const ImageWrapper: React.FunctionComponent<ImageProps> = ({
  src,
  alt,
  fullWidth,
  fullHeight,
  cover,
  aspect,
  dimensions = { width: '1420', height: '1080' },
  quality = 70,
  className,
  imgClassName,
  priority,
  blur,
  blurData,
  unoptimized,
  caption,
  itemProp,
}) => {
  // Break out if there is no src to do anything with

  if (!src || src === undefined || src === null || typeof src !== 'string') {
    return null;
  }

  const ImageClasses = clsx(
    'Image not-prose flex overflow-hidden relative',
    {
      'w-full': fullWidth,
      'h-full': fullHeight,
    },
    aspect,
    className
  );

  // Remove any funny params from the src URL
  let cleanUrl;

  if (src.startsWith('/')) {
    cleanUrl = src;
  } else {
    cleanUrl = new URL(src);
    cleanUrl.searchParams.delete('w');
    cleanUrl.searchParams.delete('h');
    cleanUrl.searchParams.delete('q');
    cleanUrl.searchParams.delete('rect');
    cleanUrl.searchParams.delete('rot');
  }

  src = cleanUrl.toString();

  const imgLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const originalWidth = dimensions.width
      ? Number(dimensions.width) * 2 < 1040
        ? Number(dimensions.width) * 2
        : 1920
      : 1920;

    return src.includes('atdw-online.com.au')
      ? `${src}?w=${originalWidth}&q=${quality || 75}&fm=webp`
      : `${src}?w=${originalWidth}&q=${quality || 75}&fm=webp`;
  };

  return (
    <span className={ImageClasses}>
      <Image
        alt={alt || 'Image'}
        //loader={imgLoader}
        width={dimensions.width ? Number(dimensions.width) : undefined}
        height={dimensions.height ? Number(dimensions.height) : undefined}
        src={src.startsWith('//') ? `https:${src}` : src}
        priority={priority}
        quality={quality}
        loading={priority ? 'eager' : 'lazy'}
        key={src}
        loader={imgLoader}
        unoptimized={unoptimized}
        placeholder={blur && blurData ? 'blur' : 'empty'}
        blurDataURL={blur && blurData ? blurData : undefined}
        className={clsx(
          'w-full duration-500 ease-out',
          imgClassName,
          cover && 'h-full object-cover object-center',
          !cover && 'object-contain'
        )}
        sizes={`(max-width: 480px) 100vw,(max-width: 780px) 40vw,(max-width: 1600px) 60vw`}
        itemProp={itemProp && 'image'}
      />
      {caption && alt && <Caption description={alt} overlay />}
    </span>
  );
};
export default ImageWrapper;
