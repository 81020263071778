import ImageWrapper from '@components/Image';
import { Typography } from '@components/Typography';
import clsx from 'clsx';
import Link from 'next/link';
import { CardProps } from './Card';

const CardImage: React.FC<CardProps> = ({
  type,
  image,
  heading,
  summary,
  link,
  className,
  priority,
}) => {
  return (
    <Link href={link} className="w-full" title={heading}>
      <span className={clsx('group relative block hover:shadow-sm', className)}>
        <span className="relative block h-[220px] overflow-hidden rounded-xl md:h-[380px]">
          <ImageWrapper
            src={image.src}
            alt={heading}
            fullHeight
            cover
            fullWidth
            dimensions={{ width: 340, height: 200 }}
            imgClassName={'group-hover:scale-105 nice-transition '}
            priority={priority}
          />
          <span className="nice-transition absolute inset-0 size-full rounded-xl border-4 border-transparent bg-black/60 group-hover:border-brand-sand group-hover:bg-black/80"></span>
        </span>
        <span className="nice-transition absolute bottom-0 left-0 right-0 top-0 block flex flex-col justify-center space-y-3 px-6 pb-0 text-white md:justify-end md:pb-8 md:group-hover:-translate-y-6">
          {/* <Typography
            tag="p"
            size="text-sm"
            weight="font-semibold"
            className="flex items-center space-x-2 capitalize text-white">
            {type === 'Place' && (
              <MarkerPin01 className="size-5" role="presentation" />
            )}
            <span>{type}</span>
          </Typography> */}
          <Typography
            tag="h4"
            size="text-xl md:text-2xl"
            weight="font-semibold">
            {heading}
          </Typography>

          {summary && (
            <Typography tag="p" size="text-base" className="line-clamp-4">
              {summary}
            </Typography>
          )}
        </span>
      </span>
    </Link>
  );
};
export default CardImage;
