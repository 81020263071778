import Card, { CardProps } from '@components/Card/Card';
import { ATDWImage } from './ATDWImage';
import { ATDWToURL } from './ATDWToURL';
import slugToURL from './SlugToURL';

type CardInheritedProps = Pick<CardProps, 'type' | 'priority'>;

interface CreateCardProps extends CardInheritedProps {
  item: any; // Consider defining a more specific type
  position?: number;
  hideFavourite?: boolean;
  dealLink?: boolean; // Do we want to link to the deal website or our listings page
}

export default function CreateCard({
  item,
  type,
  hideFavourite,
  dealLink,
}: CreateCardProps) {
  if (!item) {
    return null;
  }

  // Determine if the item is an ATDW item based on the presence of specific properties
  const isATDWItem = item.productName || item.dealName;

  const productName = isATDWItem
    ? item.dealName || item.productName?.toString().replace(/[^\x00-\x7F]/g, '')
    : item.title || item.name;

  const link = isATDWItem
    ? (dealLink === true && item.dealUrlWithTracking) ||
      ATDWToURL(
        item.productCategoryId?.toString() ?? '',
        item.objectID ?? '',
        item.productName || item.dealName,
        item.__queryID ? `queryID=${item.__queryID}` : undefined,
        item.productCategoryId?.toString() === 'DEAL' && {
          productCategoryId: item.parentProductCategoryId,
          parentProductId: item.parentProductId,
          parentProductName: item.parentProductName,
        }
      )
    : slugToURL(item.contentType || item.__typename, item.slug || item.link);

  const summary = isATDWItem
    ? item.productDescription
        ?.toString()
        .replace(/[^\x00-\x7F]/g, '')
        .substring(0, 100)
    : item.summary;

  const imageSrc = isATDWItem
    ? ATDWImage({ product: item })
    : item.hero?.image?.url ||
      item.heroCollection?.items[0]?.image?.url ||
      item.hero;

  const cardType = item.productCategoryId === 'DEAL' ? 'deal' : type;

  return (
    <Card
      type={cardType}
      key={item.objectID || item.sys?.id}
      heading={productName}
      summary={summary}
      image={{ src: imageSrc }}
      meta={{
        type:
          item.productCategoryId?.toString() ||
          item.contentType ||
          item.__typename,
        hasDeal: item?.hasDeal,
        event: isATDWItem && {
          from:
            item.frequencyStartDate?.toString() ||
            item.startDate?.toString() ||
            item.dealRedeemStartDate?.toString(),
          to:
            item.frequencyEndDate?.toString() ||
            item.endDate?.toString() ||
            item.dealRedeemEndDate?.toString(),
        },
        location: isATDWItem && item.addresses?.[0]?.cityName,
        operator: isATDWItem && item.parentProductName,
        score: isATDWItem && item.tripAdvisorDetails,
      }}
      link={link}
      favourite={!hideFavourite && { productId: item.objectID || item.sys?.id }}
      buttons={[
        {
          variant: cardType === 'deal' ? 'deal' : 'default',
          size: 'default',
          children:
            item.productCategoryId === 'DEAL' ? 'View Deal' : 'View More',
          link: link,
          fullWidth: true,
        },
      ]}
    />
  );
}
